<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-5">
              <div class="text-right w-100">
                <h3>Last Week</h3>
              </div>
              <table
                id="UPCOMING_APPOINTMENTS_TABLE_LAST_WEEK"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Doctor</th>
                    <th>Sponsor</th>
                    <th>Elderly</th>
                    <th>Last Appointment Date</th>
                    <th>Initial Next Appointment</th>
                    <th>Expected Next Appointment</th>
                    <th>Next Appointment Status</th>
                    <th>Days since expected appointment</th>
                    <th>Visits Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in lastweekupcomings" :key="item.id">
                    <td>
                      <span v-if="item.doctor != null"
                        >Dr. {{ item.doctor.firstname }}
                        {{ item.doctor.lastname }}</span
                      >
                      <span v-else>None</span>
                    </td>
                    <td>{{ item.user.name }}</td>
                    <td>{{ item.firstname }} {{ item.lastname }}</td>
                    <td>
                      {{ formatDateTime(item.lastappointment.appointment_date) }}
                    </td>
                    <td class="text-danger">
                      {{ formatDateTime(item.initial_next_appointment_date) }}
                    </td>
                    <td>{{ formatDateTime(item.next_appointment_date) }}</td>
                    <td>
                      <button
                        v-if="item.app_booked"
                        class="badge badge-success"
                      >
                        Appointment Booked
                      </button>
                      <button v-else class="badge badge-dark">
                        Not Booked Yet
                      </button>
                    </td>
                    <td>
                      <span v-if="item.initial_next_app_delay != null">{{
                        parseDelayDuration(item.initial_next_app_delay)
                      }}</span>
                    </td>
                    <td>{{ item.visit_count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div v-if="!loading" class="table-responsive mt-5">
              <div class="text-right w-100">
                <h3>This Week</h3>
              </div>
              <table
                id="UPCOMING_APPOINTMENTS_TABLE_THIS_WEEK"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Doctor</th>
                    <th>Sponsor</th>
                    <th>Elderly</th>
                    <th>Last Appointment Date</th>
                    <th>Initial Next Appointment</th>
                    <th>Expected Next Appointment</th>
                    <th>Next Appointment Status</th>
                    <th>Days since expected appointment</th>
                    <th>Visits Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in thisweekupcomings" :key="item.id">
                    <td>
                      <span v-if="item.doctor != null"
                        >Dr. {{ item.doctor.firstname }}
                        {{ item.doctor.lastname }}</span
                      >
                      <span v-else>None</span>
                    </td>
                    <td>{{ item.user.name }}</td>
                    <td>{{ item.firstname }} {{ item.lastname }}</td>
                    <td>
                      {{ formatDateTime(item.lastappointment.appointment_date) }}
                    </td>
                    <td class="text-danger">
                      {{ formatDateTime(item.initial_next_appointment_date) }}
                    </td>
                    <td>{{ formatDateTime(item.next_appointment_date) }}</td>
                    <td>
                      <button
                        v-if="item.app_booked"
                        class="badge badge-success"
                      >
                        Appointment Booked
                      </button>
                      <button v-else class="badge badge-dark">
                        Not Booked Yet
                      </button>
                    </td>
                    <td>
                      <span v-if="item.initial_next_app_delay != null">{{
                        parseDelayDuration(item.initial_next_app_delay)
                      }}</span>
                    </td>
                    <td>{{ item.visit_count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gerohttp } from "@/utils/gerocarehttp";
import { geroendpoints } from "@/utils/endpoints";
import $ from "jquery";

export default {
  name: "UpcomingAppointments",
  components: {},

  data() {
    return {
      loading: true,
      lastweekupcomings: [],
      thisweekupcomings: [],
    };
  },

  mounted() {
    this.fetchUpcomingVisits();
  },

  methods: {
    initLastTable() {
      $("#UPCOMING_APPOINTMENTS_TABLE_LAST_WEEK").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      //dt.columns([ 4, 5]).visible(false);
    },

    initThisTable() {
      $("#UPCOMING_APPOINTMENTS_TABLE_THIS_WEEK").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      // dt.columns([ 4, 5]).visible(false);
    },

    fetchUpcomingVisits() {
      this.loading = true;

      gerohttp
        .get(geroendpoints.FETCH_UPCOMING_VISITS_THIS_WEEK_AND_NEXT)
        .then((response) => {
          this.lastweekupcomings = response.lastweek;
          this.thisweekupcomings = response.thisweek;

          setTimeout(() => {
            this.initLastTable();
            this.initThisTable();
          }, 1000);

          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    parseDelayDuration(days) {
      if (days == null) {
        return "";
      } else if (days == 1) {
        return "1 day ago";
      } else if (days > 1 && days < 7) {
        return `${days} days ago`;
      } else if (days > 7 && days < 30) {
        return `${Math.floor(days / 7)} weeks ago`;
      } else if (days > 30 && days < 60) {
        return `${Math.floor(days / 30)} month ago`;
      } else if (days > 60) {
        return `${Math.floor(days / 30)} months ago`;
      }
    },
  },
};
</script>
